<template>
  <sub-page
      :title="$t('AllReferrals')"
      v-model="$store.state.settings.pageData"
      :header-action="openNewReferral"
      :header-action-text="$t('AddReferral')"
  >
    <template #default>

      <ws-data-table
          :items="items"
          :headers="headers"
          class="mt-5"
      >

        <template #item.clicks="{item}">
          <h5 class="text-center mr-4"> {{ item.clicks }}</h5>
        </template>
        <template #item.completed_businesses_count="{item}">
          <h5 class="text-center mr-4"> {{ item.completed_businesses_count }}/{{ item.businesses_count }}</h5>
        </template>

        <template #item.roles="{item}">
          <div class="d-flex align-center">
            <v-icon :color="item.is_designer ? wsACCENT : null">mdi-pencil-ruler</v-icon>
            <v-icon :color="item.is_content_manager ? wsACCENT : null">mdi-school-outline</v-icon>
          </div>
        </template>

      </ws-data-table>

    </template>

    <template #dialog>

      <ws-dialog
          v-if="displayDialog"
          v-model="displayDialog"
          :title="$t('AddReferral')"
          @save="saveReferralAction"
      >
        <template #header-after>
          <ws-navigation-header v-model="newReferralNavigation" :items="newReferralNavigationSelect"
                                padding="2px"
                                locked
          />
        </template>
        <ws-navigation v-model="newReferralNavigation" :items="newReferralNavigationSelect" hide-header>

          <template #item.user>
            <ws-new-user-input
                v-model="entityData"
            />
          </template>

          <template #item.referral>
            <h1>Add New Referral</h1>
            <code> {{ entityData }}</code>
          </template>

        </ws-navigation>


      </ws-dialog>

    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "allReferrals",
  components: {},

  data() {
    return {
      items : [],
      entityData : {},
      newEntity : false,
      displayDialog : false,
      newReferralNavigation : 'user'
    }
  },
  computed : {
    newReferralNavigationSelect() {
      return [
        { text : this.$t('User') , value : 'user' },
        { text : this.$t('Referral') , value : 'referral' }
      ]
    },
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Code') , value : 'code', width : 10 , align : 'center' },

        { text : this.$t('Clicks') , value : 'clicks' , width : 10 , align : 'center'},
        { text : this.$t('Businesses') , value : 'completed_businesses_count' , width : 10 , align : 'center'},
        { text : this.$t('Roles') , value : 'roles'  , width : 10},
      ]
    }
  },
  methods : {
    ...mapActions('adminPartners', [
      'GET_PARTNERS',
      'ADD_PARTNER_USER',
    ]),

    async addNewPartnerUser() {
      let result = await this.ADD_PARTNER_USER(this.entityData)
      if ( !result ) {
        return this.ERROR()
      }
      this.items.unshift(result)
      this.displayDialog = false
    },

    saveReferralAction() {
      if ( this.newReferralNavigation === 'user' ) {
        this.newReferralNavigation = 'referral'
        return
      }

      this.addNewPartnerUser()

    },
    openNewReferral() {
      this.newReferralNavigation = 'user'
      this.entityData = {}
      this.newEntity = true
      this.displayDialog = true
    },

    async initPage() {
      let result = await this.GET_PARTNERS()
      if ( !result ) {
        return this.ERROR()
      }
      result.items.reverse()
      this.items = result.items
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>